
export default class Pokemon {

    constructor(id, name, hp, attack, speed, defense, image, element){
        this.id = id
        this.name = name
        this.hp = hp
        this.attack = attack
        this.speed = speed
        this.defense = defense
        this.image = image
        this.element = element
    }
}